import React from 'react';

import AirplaneIcon from '../../assets/icons/airplane.svg';
import GearIcon from '../../assets/icons/gear.svg';
import HandIcon from '../../assets/icons/hand.svg';
import ShieldIcon from '../../assets/icons/shield.svg';
import PartIcon from '../../assets/icons/part.svg';
import './style.scss';

export const SectionAdvantagesList = () => (
    <div className="section-advantages-list">
        <div className="section-advantages-list__container container">
            <div className="section-advantages-list__item">
                <AirplaneIcon />
                <div className="section-advantages-list__item-text">
                    Booking a whole
                    cargo plane or
                    its part 24/7
                </div>
            </div>
            <div className="section-advantages-list__item">
                <GearIcon />
                <div className="section-advantages-list__item-text">
                    3 modes of operation at the
                    same time: Ordinary,
                    Express, Empty Legs
                </div>
            </div>
            <div className="section-advantages-list__item">
                <HandIcon />
                <div className="section-advantages-list__item-text">
                    One request - a
                    large number of
                    offers
                </div>
            </div>
            <div className="section-advantages-list__item">
                <ShieldIcon />
                <div className="section-advantages-list__item-text">
                    Confidentiality of
                    transactions without third
                    parties, including World Trans
                    Aero
                </div>
            </div>
            <div className="section-advantages-list__item">
                <PartIcon />
                <div className="section-advantages-list__item-text">
                    Empty Legs –
                    maximum savings
                </div>
            </div>
        </div>
    </div>
);
