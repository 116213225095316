import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { ButtonSm } from '../buttons';

import './style.scss';

export const ConfirmModal = ({
    title,
    isOpen,
    processing,
    onConfirm,
    onClose,
}) => (
    <Modal
        isOpen={isOpen}
        contentLabel={title}
        onRequestClose={onClose}
        style={{
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 20,
                zIndex: 999,
            },
            content: {
                border: 'none',
                backgroundColor: '#F2F4FB',
                maxWidth: 500,
                inset: 'unset',
                position: 'static',
                boxShadow: 'none',
                padding: 30,
            },
        }}
    >
        <div className="modal">
            <h3 className="modal__title modal__title-sm">
                {title}
            </h3>
            <div className="modal__buttons">
                <ButtonSm
                    text="Confirm"
                    onClick={onConfirm}
                    processing={processing}
                />
                <ButtonSm
                    text="Close"
                    onClick={onClose}
                />
            </div>
        </div>
    </Modal>
);

ConfirmModal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    processing: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

ConfirmModal.defaultProps = {
    title: '',
    isOpen: false,
    processing: false,
    onConfirm: () => {},
    onClose: () => {},
};
