import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { setNotification } from '~/components/notification';
import { smartClass } from '~/helpers/smart-class';
import { getAircraftBySlug } from '~/services/aircrafts';

import TriangleBottomIcon from '../assets/triangle-bottom.svg';

import './style.css';

export class AircraftSelect extends PureComponent {
    state = {
        options: [],
        value: null,
        loading: null,
    }

    selectRef = null;

    componentDidUpdate(prevProps) {
        const { selectedValue } = this.props;

        if (prevProps.selectedValue !== selectedValue) {
            this.getSelectedValue();
        }
    }

    getNoOptionsMessage = ({ inputValue }) => {
        const { options } = this.state;

        if (inputValue && options.length < 1) {
            return '';
        }

        return 'To find start writing';
    }

    getSelectedValue = async () => {
        const { selectedValue } = this.props;

        if (!selectedValue) {
            this.setState({ value: null });
            return;
        }

        this.setState({ loading: 'Loading ...' });

        this.setState({
            value: {
                value: selectedValue,
                label: selectedValue,
            },
        });

        this.setState({ loading: null });
    }

    handleOnChange = (aircraft) => {
        const { loading } = this.state;

        if (loading) {
            return;
        }

        const { onChange, onChangeWeight, onChangeVolume } = this.props;
        onChangeWeight({ value: aircraft.weight });
        onChangeVolume({ value: aircraft.volume });
        onChange(aircraft.name);
    }

    promiseOptions = (slug) => new Promise((resolve) => {
        const { onChange } = this.props;
        onChange(slug);

        clearTimeout(this.timeout);
        if (!slug) {
            resolve([]);
            return;
        }

        this.timeout = setTimeout(async () => {
            try {
                const data = getAircraftBySlug(slug);
                this.setState({ options: data });

                // eslint-disable-next-line max-len
                resolve(data.map((aircraft) => ({ value: aircraft.name, label: aircraft.name, name: aircraft.name, weight: aircraft.weight, volume: aircraft.volume })));
            } catch (err) {
                setNotification({ type: 'error' });
                resolve([]);
            }
        }, 500);
    });

    render() {
        const { value, loading } = this.state;
        const { placeholder, modClass, disabled } = this.props;

        const customStyles = {
            option: (provided) => ({
                ...provided,
                color: '#11192B',
                '&:hover': {
                    background: '#0E3CC0',
                    color: '#fff',
                },
            }),
            control: (provided, state) => ({
                ...provided,
                border: state.isFocused ? '2px solid #11192B66' : '1px solid #11192B66',
                boxShadow: 'none',
                height: '55px',
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'black',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: '#11192B66',
            }),
        };

        return (
            <div className={smartClass('control-form-select-container control-form-select-airport', modClass)}>
                <AsyncSelect
                    placeholder={loading || placeholder}
                    isDisabled={disabled}
                    defaultProps
                    optionsCache
                    onChange={this.handleOnChange}
                    noOptionsMessage={this.getNoOptionsMessage}
                    loadOptions={this.promiseOptions}
                    classNamePrefix="control-form-select"
                    styles={customStyles}
                    value={loading ? null : value}
                    components={{
                        DropdownIndicator: () => <TriangleBottomIcon />,
                        IndicatorSeparator: () => null,
                    }}
                />
            </div>
        );
    }
}

AircraftSelect.propTypes = {
    placeholder: PropTypes.string,
    modClass: PropTypes.string,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
};

AircraftSelect.defaultProps = {
    placeholder: 'Aircraft',
    modClass: '',
    selectedValue: '',
    onChange: () => { },
};
