import React, { useState, useEffect } from 'react';

export const CounterBlock = ({ time, step, endNmb }) => {
    const [currentNmb, setNmb] = useState(0);

    const setNbmTimer = () => {
        const t = Math.round(time / (endNmb / step));

        const timer = setInterval(() => {
            currentNmb + step <= endNmb ? setNmb(currentNmb + step) : setNmb(endNmb);
            clearInterval(timer);
        }, t);
    };

    useEffect(
        () => {
            if (endNmb === currentNmb) {
                return;
            }
            setNbmTimer();
        },
        [currentNmb],
    );

    return (
        <span className="counter">
            {currentNmb.toLocaleString('ru')}
        </span>
    );
};
