import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { ButtonColor } from '../buttons';

import './style.scss';

export const BasicModal = ({
    title,
    isOpen,
    isLoading,
    content,
    onClose,
}) => (
    <Modal
        isOpen={isOpen}
        contentLabel={title}
        onRequestClose={onClose}
        style={{
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 20,
                zIndex: 999,
            },
            content: {
                border: 'none',
                backgroundColor: '#F2F4FB',
                maxWidth: 800,
                width: '100%',
                inset: 'unset',
                position: 'static',
                boxShadow: 'none',
                borderRadius: '10px',
                padding: 40,
            },
        }}
    >
        <div className="modal">
            <h3 className="modal__title">
                {title}
            </h3>

            <div className="modal__content">
                {isLoading && (
                    <div className="modal-content-loading">Loading data...</div>
                )}
                {!isLoading && content}
            </div>

            <div className="modal__buttons">
                <ButtonColor
                    text="Close"
                    onClick={onClose}
                />
            </div>
        </div>
    </Modal>
);

BasicModal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    content: PropTypes.any,
    onClose: PropTypes.func,
};

BasicModal.defaultProps = {
    title: '',
    isOpen: false,
    isLoading: false,
    content: null,
    onClose: () => {},
};
