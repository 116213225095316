import React, { useState } from 'react';

import AirplaneIcon from '../../assets/icons/airplane.svg';
import ShieldIcon from '../../assets/icons/shield.svg';
import CoubIcon from '../../assets/icons/coub.svg';
import DesktopIcon from '../../assets/icons/desktop.svg';
import PaperIcon from '../../assets/icons/paper.svg';
import PlanetIcon from '../../assets/icons/planet.svg';
import RingIcon from '../../assets/icons/ring.svg';

import './style.scss';

export const SectionAdvantages = () => {
    const [type, setType] = useState('broker');

    const BrokerHtml = () => (
        <div className="section-advantages__list">
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <DesktopIcon />
                    </div>
                    <div className="section-advantages__title">
                        Absence of turbulence
                    </div>
                </div>
                <div className="section-advantages__text">
                    Easily specify the route, date and information about the cargo, the platform will do the rest for you.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <PaperIcon />
                    </div>
                    <div className="section-advantages__title">
                        Convenience at a height
                    </div>
                </div>
                <div className="section-advantages__text">
                    Get the maximum number of offers per request in a user-friendly interface.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <ShieldIcon />
                    </div>
                    <div className="section-advantages__title">
                        Confidentiality and reliability
                    </div>
                </div>
                <div className="section-advantages__text">
                    Direct transaction without third parties, including World Trans Aero. Get secure access for confident communication with contractors.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <PlanetIcon />
                    </div>
                    <div className="section-advantages__title">
                        New horizons
                    </div>
                </div>
                <div className="section-advantages__text">
                    The platform makes it much easier to enter new markets due to a ready solution for working all over the world.
                </div>
            </div>

            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <RingIcon />
                    </div>
                    <div className="section-advantages__title">
                        Supersonic solutions
                    </div>
                </div>
                <div className="section-advantages__text">
                    Instant notification of operators about each order. The offers contain only current prices.
                </div>
            </div>
        </div>
    );

    const OperatorHtml = () => (
        <div className="section-advantages__list">
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <AirplaneIcon />
                    </div>
                    <div className="section-advantages__title">
                        Convenience on autopilot
                    </div>
                </div>
                <div className="section-advantages__text">
                    Just set up the processes in a user-friendly interface, the platform will do the rest for you.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <CoubIcon />
                    </div>
                    <div className="section-advantages__title">
                        All the orders in one place
                    </div>
                </div>
                <div className="section-advantages__text">
                    Connect online order board that meet the settings and locations of your aircraft.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <ShieldIcon />
                    </div>
                    <div className="section-advantages__title">
                        Absolute confidentiality
                    </div>
                </div>
                <div className="section-advantages__text">
                    Direct transaction without third parties, including World Trans Aero. Get secure access for confident communication with contractors.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <PlanetIcon />
                    </div>
                    <div className="section-advantages__title">
                        Expanded geography
                    </div>
                </div>
                <div className="section-advantages__text">
                    The platform makes it much easier to enter new markets due to a ready solution for working all over the world.
                </div>
            </div>
            <div className="section-advantages__item">
                <div className="section-advantages__item-top">
                    <div className="section-advantages__item-icon">
                        <RingIcon />
                    </div>
                    <div className="section-advantages__title">
                        Speed of solutions
                    </div>
                </div>
                <div className="section-advantages__text">
                    Instant notification of each request. Data exchange takes place in real time.
                </div>
            </div>
        </div>
    );

    const handleClick = (newType) => {
        setType(newType);
    };
    const isBroker = type === 'broker';

    return (
        <div id="anchor-advantages" className="section-advantages">
            <div className="container">
                <div className="block-title_big section-advantages_title">
                    Advantages of World Trans Aero
                </div>
                <div className="section-advantages__tabs">
                    <div
                        aria-hidden="true"
                        onClick={() => handleClick('broker')}
                        className={`section-advantages__tab ${type === 'broker' ? 'active' : ''}`}
                    >
                        Broker / Customer
                    </div>
                    <div
                        aria-hidden="true"
                        onClick={() => handleClick('operator')}
                        className={`section-advantages__tab ${type === 'operator' ? 'active' : ''}`}
                    >
                        Operator / Airline
                    </div>
                </div>

                {isBroker ? <BrokerHtml /> : <OperatorHtml />}

                <div className="section-advantages__regimes">
                    <div className="block-title_big">
                        3 operating regimes at the same time
                    </div>
                    <div className="section-advantages__regimes-list">
                        <div className="section-advantages__regimes-item">
                            <div className="section-advantages__regimes-icon">
                                1
                            </div>
                            <div className="block-title section-advantages__regimes-title">
                                Ordinary
                            </div>
                            <div className="section-advantages__regimes-text">
                                Get a lot of offers
                            </div>
                        </div>
                        <div className="section-advantages__regimes-item">
                            <div className="section-advantages__regimes-icon">
                                2
                            </div>
                            <div className="block-title section-advantages__regimes-title">
                                Express
                            </div>
                            <div className="section-advantages__regimes-text">
                                The best way to save time
                            </div>
                        </div>
                        <div className="section-advantages__regimes-item">
                            <div className="section-advantages__regimes-icon">
                                3
                            </div>
                            <div className="block-title section-advantages__regimes-title">
                                Empty Legs
                            </div>
                            <div className="section-advantages__regimes-text">
                                Maximum financial savings
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
