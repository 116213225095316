import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';

export const Button = ({
    text = 'Текст',
    type,
    modClass,
    onClick,
    size,
    disabled,
    dark,
    wide,
}) => (
    <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={smartClass('button',
            {
                [modClass]: !!modClass,
                'main-button--sm': size === 'sm',
                'button_dark': dark,
                'button_wide': wide,
            })}
    >
        <>
            {text}
        </>
    </button>
);

Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    modClass: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    text: '',
    type: 'button',
    modClass: '',
    size: '',
    onClick: () => { },
    dark: false,
    disabled: false,
};
