import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { ButtonColor } from '../buttons';

import './style.scss';

export const StopModal = () => (
    <Modal
        isOpen
        style={{
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 30,
                zIndex: 999,
            },
            content: {
                border: 'none',
                backgroundColor: '#f2f4fb',
                maxWidth: 442,
                width: '100%',
                inset: 'unset',
                position: 'static',
                padding: 40,
            },
        }}
    >
        <div className="modal stop-modal">
            <h3 className="modal__title">
                Please finish setting up your account
            </h3>
            <div className="modal__content">
                Fill in the last 3 links in the “Company details” section and start working
            </div>
            <ButtonColor
                text="OK"
                onClick={() => { window.location.href = '/settings'; }}
                wide
            />
        </div>
    </Modal>
);

StopModal.defaultProps = {
    title: '',
    isOpen: false,
    isLoading: false,
    content: null,
    onClose: () => { },
};
