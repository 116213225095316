import React from 'react';

import { ButtonColor } from '../../../../components/buttons/buttonColor';
import { smoothScrollingTo } from '../../../../helpers/scroll';
import { CounterBlock } from '../../../../components/counter';
import './style.scss';

export const SectionBanner = () => (
    <div className="main-banner">
        <div className="main-banner__container">
            <div className="main-banner__left">
                <div className="main-banner__locations">
                    <div className="main-banner__locations-item">
                        <CounterBlock
                            time={1300}
                            step={24}
                            endNmb={193}
                        />
                        &nbsp;Countries
                    </div>
                    <div className="main-banner__locations-item">
                        <CounterBlock
                            time={1300}
                            step={452}
                            endNmb={16040}
                        />
                        &nbsp;Cities
                    </div>
                    <div className="main-banner__locations-item">
                        <CounterBlock
                            time={1300}
                            step={640}
                            endNmb={28897}
                        />
                        &nbsp;Airports
                    </div>
                </div>
                <div className="main-banner__title">
                    Global search platform to book air cargo charter
                </div>
                <div className="main-banner__text">
                    Register on World Trans Aero now and receive a free period until 31 December 2024
                </div>
                <ButtonColor onClick={() => smoothScrollingTo('anchor-join')} text="Get started" wide />
            </div>
        </div>
    </div>
);
