import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export const Textarea = ({
    value, placeholder, onChange, disabled, maxLength,
}) => (
    <div className="control-form control-form--textarea">
        <textarea
            rows="8"
            value={value.charAt(0).toUpperCase() + value.slice(1)}
            onChange={onChange}
            placeholder={placeholder}
            className="control-form__input"
            disabled={disabled}
            maxLength={maxLength || 1000}
        />
    </div>
);

Textarea.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

Textarea.defaultProps = {
    value: '',
    placeholder: '',
    onChange: () => { },
};
