import React from 'react';

import './style.scss';

export const Checkbox = ({
    checked, onChange,
}) => (
    <label className="container-checkbox control-form--checkbox">
        <input
            type="checkbox"
            checked={checked}
            className="control-form__checkbox"
            onChange={onChange}
        />
        <span className="checkmark" />
    </label>
);
