import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';

import { getServerData } from '~/core/get-server-data';

import { App } from './app';
import { getStore } from './redux/store';

const serverData = getServerData();
const store = getStore(serverData);

hydrate(
    <Provider store={store}>
        <App />
    </Provider>,
    document.querySelector('#app'),
);
