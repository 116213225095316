import { toast } from 'react-toastify';

export const setNotification = ({ type, message }) => {
    const notifiCationModel = {
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    let defaultMessage = '';

    switch (type) {
    case 'info':
        break;
    case 'success':
        defaultMessage = 'Successful!';
        break;
    case 'warning':
        defaultMessage = 'Something went wrong!';
        break;
    case 'error':
        defaultMessage = 'An error has occurred!';
        break;
    default:
        break;
    }

    const sendMessage = `${message || defaultMessage}`;

    if (type) {
        return toast[type](sendMessage, notifiCationModel);
    }

    return toast(sendMessage, notifiCationModel);
};
