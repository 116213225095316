export const model = {
    menuLinks: [
        {
            name: "Advantages",
            scroll: "anchor-advantages",
            mobile: true,
            logged: false,
        },
        {
            name: "Workflow",
            scroll: "anchor-workflow",
            logged: false,
            mobile: true,
        },
    ],
};
