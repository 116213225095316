import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';

import LoaderIcon from '../../../assets/images/loader.svg';

import './style.css';

export const ButtonSm = ({
    text = 'text', onClick, processing, wide,
}) => (
    <button
        type="button"
        className={smartClass('button-sm', {
            'button-sm_wide': wide,
        })}
        onClick={onClick}
    >
        {!processing && text}
        {processing && <span className="button-sm__loader"><LoaderIcon /></span>}
    </button>
);

ButtonSm.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    processing: PropTypes.bool,
    wide: PropTypes.bool,
};

ButtonSm.defaultProps = {
    text: '',
    onClick: () => { },
    processing: false,
    wide: false,
};
