export const smoothScrollingTo = (scrollId) => {
    const scrollElement = window.document.getElementById(scrollId);

    if (!scrollElement) {
        document.location.href = `/#${scrollId}`;
        return;
    }

    window.scroll({
        behavior: 'smooth',
        left: 0,
        top: scrollElement.offsetTop - 100,
    });
};
