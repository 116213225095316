import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import axios from 'axios';
import { ButtonColor } from '../../buttons';
import { Input, Textarea, Checkbox } from '../../controls';
import { setNotification } from '../../notification';
import { CloseIcon } from './assets/close.svg';

import './style.scss';

export const AskModalQuestion = ({
    title,
    isOpen,
    changeStateModal,
}) => {
    const [formValues, setValueForm] = useState({});

    const onChangeValues = (value) => {
        setValueForm({
            ...formValues,
            ...value,
        });
    };

    // TODO: вынести в redux
    const sendQuestion = async () => {
        if (
            !formValues.company
            || !formValues.email
            || !formValues.name
            || !formValues.mess
        ) {
            setNotification({
                type: 'error',
                message: 'Fill in all the fields',
            });
            return;
        }
        try {
            await axios.post('/user/send-form', formValues);

            setNotification({
                type: 'success',
                message: 'Sent',
            });
            changeStateModal(false);
            setValueForm({});
        } catch (err) {
            setNotification({ type: 'error' });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            contentLabel={title}
            onRequestClose={() => changeStateModal(false)}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 40,
                    zIndex: 999,
                },
                content: {
                    border: 'none',
                    backgroundColor: '#F2F4FB',
                    maxWidth: 400,
                    width: '100%',
                    inset: 'unset',
                    position: 'static',
                    boxShadow: 'none',
                    borderRadius: '10px',
                },
            }}
        >
            <div className="modal modal__question">
                <h3 className="modal__title block-title">
                    Ask a question
                </h3>
                <div className="close-btn">
                    <CloseIcon onClick={() => changeStateModal(false)} />
                </div>
                <div className="modal__body">
                    <Input
                        placeholder="Company"
                        value={formValues.company || ''}
                        onChange={(e) => onChangeValues({ company: e.target.value })}
                    />
                    <Input
                        placeholder="Name"
                        value={formValues.name || ''}
                        onChange={(e) => onChangeValues({ name: e.target.value })}
                    />
                    <Input
                        placeholder="Email"
                        value={formValues.email || ''}
                        onChange={(e) => onChangeValues({ email: e.target.value.toLowerCase() })}
                    />
                    <Textarea
                        placeholder="Message"
                        value={formValues.mess || ''}
                        onChange={(e) => onChangeValues({ mess: e.target.value })}
                    />
                    <div className="agree_checkbox">
                        <Checkbox
                            checked={formValues.checkbox}
                            onChange={() => onChangeValues({ checkbox: !formValues.checkbox })}
                        />
                        <div className="agree_checkbox__text">
                            I accept the terms of the
                            <br />
                            {' '}
                            <a href="/privacy-policy">User  Agreement</a>
                        </div>
                    </div>

                </div>
                <ButtonColor
                    disabled={!formValues.checkbox}
                    text="Send"
                    onClick={() => sendQuestion()}
                    wide
                />
            </div>
        </Modal>
    );
};

AskModalQuestion.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    changeStateModal: PropTypes.func,
};

AskModalQuestion.defaultProps = {
    title: '',
    isOpen: false,
    changeStateModal: () => { },
};
