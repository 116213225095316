const longControlsLimit = 4;

export const getRequestControls = (requestControls) => (requestControls || []).reduce(
    (acc, curr, index) => {
        if (index + 1 > longControlsLimit) {
            return {
                ...acc,
                short: [...acc.short, curr],
            };
        }

        return {
            ...acc,
            long: [...acc.long, curr],
        };
    },
    { short: [], long: [] },
);
