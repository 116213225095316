import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

import { smartClass } from '~/helpers/smart-class';

import './style.css';

export const Dropdown = ({ text, buttons }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleClick = (callback) => {
        setIsCollapsed(false);
        callback();
    };

    return (
        <OutsideClickHandler onOutsideClick={() => isCollapsed && setIsCollapsed(false)}>
            <div className="dropdown">
                <div
                    className={smartClass('dropdown__container', {
                        'dropdown__container_collapsed': isCollapsed,
                    })}
                >
                    <button
                        type="button"
                        className="dropdown__button"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {text}
                        <span className="icon icon_arrow-down" />
                    </button>

                    {isCollapsed && buttons.map(({ id, onClick, text: buttonText }) => (
                        <button
                            key={id}
                            type="button"
                            className="dropdown__button"
                            onClick={() => handleClick(onClick)}
                        >
                            {buttonText}
                        </button>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

Dropdown.propTypes = {
    text: PropTypes.string,
    buttons: PropTypes.array,
};

Dropdown.defaultProps = {
    text: 'Action',
    buttons: [],
};
