import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectReact, { components } from 'react-select';
import { smartClass } from '~/helpers/smart-class';
import Triangle from './assets/triangle-bottom.svg';
import './style.css';

export const Select = ({
    placeholder, options = [], selectedValue, onChange, modClass, disabled, justifyContent = 'left', valueOptions = false,
}) => {
    const handleChange = ({ value }) => {
        onChange(value);
    };
    const styles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            padding: '7px',
            borderColor: 'rgba(17, 25, 43, 0.4) !important',
            backgroundColor: state.isDisabled ? 'rgb(242, 242, 242)' : 'transparent',
            opacity: state.isDisabled ? '0.7' : '1',
            boxShadow: 'none',
            backgroundImage: 'url("./assets/triangle-bottom.svg")',
            backgroundPosition: 'center right 10px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '10px 8px',
            zIndex: 20,
            height: '55px',
        }),
        menu: (base) => ({ ...base, zIndex: 25 }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#11192B66',
        }),
        valueContainer: (baseStyles) => ({ ...baseStyles, justifyContent }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#11192B',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#0E3CC0' : '',
            color: state.isFocused ? '#fff' : '',
            padding: '15px 15px',
        }),
    };

    const DropdownIndicator = (
        props,
    ) => (
        <components.DropdownIndicator {...props}>
            <Triangle />
        </components.DropdownIndicator>
    );

    const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="one-time-code" />;
    const valOptions = valueOptions || options;

    return (
        <div className={smartClass('control-form', modClass)}>
            <SelectReact
                components={{ DropdownIndicator, IndicatorSeparator: () => null, Input }}
                value={valOptions.filter((option) => option.value === selectedValue)}
                onChange={handleChange}
                className="control-form"
                options={options}
                placeholder={placeholder}
                isDisabled={disabled}
                styles={styles}
                menuPosition="relative"
            />
        </div>
    );
};

Select.propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    modClass: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

Select.defaultProps = {
    options: [],
    selectedValue: undefined,
    onChange: () => { },
    modClass: '',
    placeholder: 'Select',
    disabled: false,
};
