import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import 'focus-visible';

import { SiteHeader } from '~/components/site-header';
import { SiteFooter } from '~/components/site-footer';
import { SectionJoin } from './components/section-join';
import { SectionBanner } from './components/section-banner';
import { SectionProblem } from './components/section-problem/SectionProblem';
import { SectionSolution } from './components/section-solution';
import { SectionAdvantagesList } from './components/section-advantages-list';
import { SectionWorkflow } from './components/section-workflow';
import { SectionAdvantages } from './components/section-advantages';

import {
    changeJoinValues,
    fetchJoin,
} from './redux/actions';

class MainPage extends PureComponent {
    render() {
        const {
            onChangeBlock,
            joinSelectedValues,
            joinProcessing,
            onChangeJoinValues,
            onFetchJoin,
            user,
            url,
        } = this.props;

        return (
            <>
                <SiteHeader
                    posAbsolute
                    documentTitle="Book Cargo Plane - Air Cargo Charter | World Trans Aero"
                    user={user}
                    onChangeCurrentBlock={onChangeBlock}
                    url={url}
                />
                <SectionBanner />
                <SectionAdvantagesList />
                <SectionProblem />
                <SectionSolution />
                <SectionAdvantages />
                <SectionWorkflow />
                {user === null && (
                    <SectionJoin
                        selected={joinSelectedValues}
                        processing={joinProcessing}
                        onChangeValues={onChangeJoinValues}
                        onSubmit={onFetchJoin}
                    />
                )}
                <SiteFooter showCopySupport />
            </>
        );
    }
}

MainPage.propTypes = {
    onChangeBlock: PropTypes.func,
    joinSelectedValues: PropTypes.object,
    joinProcessing: PropTypes.bool,
    onChangeJoinValues: PropTypes.func,
    onFetchJoin: PropTypes.func,
    user: PropTypes.object,
    url: PropTypes.string,
};

MainPage.defaultProps = {
    onChangeBlock: () => { },
    joinSelectedValues: {},
    joinProcessing: false,
    onChangeJoinValues: () => { },
    onFetchJoin: () => { },
    user: null,
    url: '',
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
    onChangeJoinValues(payload) {
        dispatch(changeJoinValues(payload));
    },
    onFetchJoin() {
        dispatch(fetchJoin());
    },
});

export const App = connect(mapStateToProps, mapDispatchToProps)(MainPage);
