/* eslint-disable max-len */
import React, { useState, useRef } from 'react';

import video from '../../assets/video/video.mp4';
import './style.scss';

export const SectionSolution = () => {
    const [btnState, setBtnState] = useState('play');
    const vidRef = useRef(null);

    const handleBtnClick = () => {
        if (btnState === 'pause') {
            vidRef.current.play();
            setBtnState('play');
        } else {
            vidRef.current.pause();
            setBtnState('pause');
        }
    };

    return (
        <div id="anchor-about" className="section-solution">
            <div className="container">
                <div className="section-solution__right">
                    <div className="section-solution__subtitle">
                        Solution
                    </div>
                    <h2 className="block-title section-solution__title">
                        World Trans Aero - automates processes and unites all participants of the charter air transportation market on a single search platform.
                    </h2>
                    <div className="section-solution__text">
                        <p>
                            Increase the number of offers and requests, save time and maximize your profits. World Trans Aero will help you select, sell or buy a suitable cargo charter on the best terms.
                        </p>
                    </div>
                </div>
                <div className="section-solution__left controls">
                    <video src="./video.mp4" autoPlay="autoplay" loop muted type="video/mp4" ref={vidRef} />
                    <div
                        className={`video-btn ${btnState}`}
                        onClick={() => handleBtnClick()}
                        onKeyDown={() => handleBtnClick()}
                        role="button"
                        aria-label="control"
                        tabIndex={0}
                    />
                </div>
            </div>
        </div>
    );
};
