import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export const Xmark = ({
    onClick
}) => (
    <button
        type="button"
        className="xmark-btn"
        onClick={onClick}
    >
    </button>
);

Xmark.propTypes = {
    onClick: PropTypes.func,
};

Xmark.defaultProps = {
    onClick: () => { },
};
