import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { NotificationContainer } from '../notification';
import { CookieModal, AskModalQuestion } from '../modals';
import { Button } from '../buttons/button';

import LogoImg from '../../assets/images/logo.svg';
import TelegramIcon from '../../assets/images/icons/telegram.svg';
import WhatsappIcon from '../../assets/images/icons/whatsapp.svg';
import './style.scss';

export const SiteFooter = () => {
    ReactGA.initialize('G-SXTL4R6R8B');
    ReactGA.send('pageview');

    const [isOpenModal, setIsOpenModal] = useState(false);

    const changeStateModal = (open) => {
        setIsOpenModal(open);
    };

    return (
        <>
            <CookieModal />
            <footer className="footer">
                <div className="container">
                    <div className="footer__top">
                        <a href="/" className="footer__logo">
                            <LogoImg />
                        </a>
                        <div className="footer__middle">
                            <div className="footer__logo footer__logo_mobile">
                                <LogoImg />
                            </div>
                            <div className="footer__contacts">
                                <div className="footer__title">Contacts</div>
                                <div className="footer__tel">
                                    <a href="https://wa.me/971585096159">
                                        + 971 58 509 6159
                                    </a>
                                </div>
                                <div className="footer__email">
                                    <a href="mailto:support@worldtransaero.com ">
                                        support@worldtransaero.com
                                    </a>
                                </div>
                                <div className="footer__social">
                                    <a
                                        href="https://t.me/WorldTransAero"
                                        className="footer__social-telegram"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <TelegramIcon />
                                    </a>
                                    <a
                                        href="https://wa.me/971585096159"
                                        className="footer__social-whatsapp"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <WhatsappIcon />
                                    </a>
                                </div>
                            </div>
                            <div className="footer__form">
                                <div className="footer__form-title">
                                    Need additional details?
                                </div>
                                <Button
                                    onClick={() => changeStateModal(true)}
                                    text="Ask a question"
                                    wide
                                    dark
                                />
                            </div>
                        </div>
                        <div className="footer__bottom">
                            <span className="footer__bottom-copyright">
                                © 2024 WorldTransAero
                            </span>
                            <span className="footer__bottom-separator">
                                /&nbsp;
                            </span>
                            <div className="footer__bottom-wrap">
                                <a href="/privacy-policy">Privacy policy</a>
                                <span className="footer__bottom-separator">
                                    /&nbsp;
                                </span>
                                <a href="/terms">
                                    Website terms and conditions
                                </a>
                                <span className="footer__bottom-separator">
                                    /&nbsp;
                                </span>
                                <a href="/cookie-policy">Cookie policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <NotificationContainer />
            <AskModalQuestion
                changeStateModal={changeStateModal}
                isOpen={isOpenModal}
            />
        </>
    );
};
