/* eslint-disable max-len */
import React, { useState } from 'react';

import { TYPE_IDS } from '~/libs/user';

import { MainTitle } from '~/components/titles';
import { Input, Select, Checkbox } from '~/components/controls';
import { ButtonColor } from '../../../../components/buttons/buttonColor';

import startImg from '../../assets/img/how-start.png';
import startImgMobile from '../../assets/img/how-start-mobile.png';
import './style.scss';

type Selected = {
    name: string;
    lastName: string;
    email: string;
    password: string;
    phone: number;
    repeatPassword: string;
    type: boolean;
    checkbox: boolean;
};

type Props = {
    selected: Selected;
    onChangeValues: (object) => void;
    onSubmit: () => void;
};

export const SectionJoin = ({
    selected,
    onChangeValues,
    onSubmit,
}: Props): JSX.Element => {
    const [showPass, setShowPass] = useState(false);

    return (
        <section className="section section-join">
            <div className="section-join__background-block">
                <MainTitle text="Join World Trans Aero" size="big" />
                <p className="section-join__subtitle">
                    Register on the platform now and receive a free period until 31 December
                    2024
                </p>
            </div>
            <div className="container section-join__container">
                <div className="section-join__start">
                    <div className="block-title">
                        How to start working on the platform
                    </div>
                    <div className="section-join__wrap">
                        <div className="section-join__start__img">
                            <img alt="start" src={startImg} />
                        </div>
                        <div className="section-join__start__img-mobile">
                            <img src={startImgMobile} alt="" />
                        </div>
                        <div className="section-join__start-list">
                            <div className="section-join__start__text">
                                Register in your personal cabinet, receive an e-mail notification and gain access to the platform within 12 hours after verification
                            </div>
                            <div className="section-join__start__text">
                                Complete 3 points: country, company name, site. Read the "Guide" section on working in your personal cabinet
                            </div>
                            <div className="section-join__start__text">
                                Start work: accept orders, send offers, conclude transactions on the World Trans Aero platform
                            </div>
                        </div>
                    </div>
                </div>
                <div id="anchor-join" className="section-join__form">
                    <div className="section-join__form-container">
                        <div className="section-join__form-row">
                            <div className="section-join__form-left">
                                <div className="section-join__form-left-title">
                                    Your contacts
                                </div>
                            </div>
                            <div className="section-join__form-right">
                                <div className="section-join__form-columns">
                                    <Input
                                        placeholder="First name"
                                        value={selected.name || ''}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => onChangeValues({ name: e.target.value })}
                                        capitalLetter
                                    />
                                    <Input
                                        placeholder="Last name"
                                        value={selected.lastName || ''}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => onChangeValues({ lastName: e.target.value })}
                                        capitalLetter
                                    />
                                </div>
                                <Input
                                    placeholder="Work email"
                                    value={selected.email || ''}
                                    onChange={(e) => onChangeValues({ email: e.target.value.toLowerCase() })}
                                />
                                <Input
                                    placeholder="Business phone"
                                    value={selected.phone || ''}
                                    onChange={(e) => onChangeValues({ phone: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="section-join__form-row">
                            <div className="section-join__form-left">
                                <div className="section-join__form-left-title">Company profile</div>
                            </div>
                            <div className="section-join__form-right">
                                <Select
                                    placeholder="Company"
                                    selectedValue={selected.type}
                                    onChange={(value: number) => onChangeValues({ type: value })}
                                    options={[
                                        { label: 'Broker / Customer', value: TYPE_IDS.customer },
                                        { label: 'Operator / Airline', value: TYPE_IDS.owner },
                                    ]}
                                />
                                <Input
                                    placeholder="Password"
                                    type={showPass ? 'text' : 'password'}
                                    showPass={showPass}
                                    setShowPass={setShowPass}
                                    value={selected.password || ''}
                                    onChange={(e) => onChangeValues({ password: e.target.value })}
                                />
                                <Input
                                    type={showPass ? 'text' : 'password'}
                                    showPass
                                    placeholder="Repeat password"
                                    value={selected.repeatPassword || ''}
                                    onChange={(e) => onChangeValues({ repeatPassword: e.target.value })}
                                />
                                <div className="send_wrapper">
                                    <div className="agree_checkbox">
                                        <Checkbox
                                            checked={selected.checkbox}
                                            onChange={() => onChangeValues({ checkbox: !selected.checkbox })}
                                        />
                                        <div className="agree_checkbox__text">
                                            I accept the
                                            {' '}
                                            <a href="/terms">Terms of services</a>
                                            {' '}
                                            and
                                            {' '}
                                            <a href="/privacy-policy">Privacy Policy</a>
                                        </div>
                                    </div>
                                    <ButtonColor
                                        disabled={!selected.checkbox}
                                        type="submit"
                                        text="Sign up"
                                        modClass="section-faq__form-button"
                                        size="sm"
                                        wide
                                        onClick={() => onSubmit()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
