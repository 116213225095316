export const changeBlock = (state, { currentBlockId }) => ({
    ...state,
    currentBlockId,
});

export const changeRequestValues = (state, { payload }) => ({
    ...state,
    requestSelectedValues: {
        ...state.requestSelectedValues,
        ...payload,
    },
});

export const changeQuestionValues = (state, { payload }) => ({
    ...state,
    questionSelectedValues: {
        ...state.questionSelectedValues,
        ...payload,
    },
});

export const changeJoinValues = (state, { payload }) => ({
    ...state,
    joinSelectedValues: {
        ...state.joinSelectedValues,
        ...payload,
    },
});

export const clearRequestValues = (state) => ({
    ...state,
    requestSelectedValues: {},
});

export const clearQuestionValues = (state) => ({
    ...state,
    questionSelectedValues: {},
});

export const clearJoinValues = (state) => ({
    ...state,
    joinSelectedValues: {},
});

export const setRequestProcessing = (state, { requestProcessing }) => ({
    ...state,
    requestProcessing,
});

export const setQuestionProcessing = (state, { questionProcessing }) => ({
    ...state,
    questionProcessing,
});

export const setJoinProcessing = (state, { joinProcessing }) => ({
    ...state,
    joinProcessing,
});
