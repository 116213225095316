import React, { PureComponent } from 'react';
import PropTypes, { bool } from 'prop-types';
import { smartClass } from '~/helpers/smart-class';

import { MobileMenu } from './components/mobile-menu';
import BurgerIcon from './assets/new-burger.svg';
import { model } from './model';
import { AskModalQuestion, StopModal } from '../modals';

import { Button } from '../buttons/button';
import { ButtonColor } from '../buttons/buttonColor';

import LogoImg from '../../assets/images/logo.svg';
import { smoothScrollingTo } from '../../helpers/scroll';
import './style.scss';

export class SiteHeader extends PureComponent {
    state = {
        activeRoute: '',
        isOpen: false,
        headerClassName: 'header',
        isOpenModal: false,
    }

    componentDidMount() {
        this.setState({
            headerClassName: smartClass('header',
                {
                    'absolute': this.props.posAbsolute,
                }),
            activeRoute: window.location.pathname,
        });
        document.title = this.props.documentTitle;
    }

    onCloseMenu = (e = null, forceClose = false) => {
        const { target } = e;
        // проверка на клик по оверлею
        if (!target.classList.contains('overlay') && !forceClose) {
            return;
        }

        this.setState({ isOpen: false });
    }

    onOpenMenu = () => {
        this.setState({ isOpen: true });
    }

    getMenuLinks() {
        const { user } = this.props;

        if (!model && !model.menuLinks) {
            return [];
        }

        const links = model.menuLinks;

        if (user) {
            const filtered = links.filter((link) => typeof link.logged === 'undefined' || link.logged);

            return [
                ...filtered,
            ];
        }

        return links;
    }

    handleOnClickLink = (e, scrollId, isMobile) => {
        if (!scrollId) {
            return;
        }

        e.preventDefault();

        if (isMobile) {
            this.onCloseMenu(e, true);
        }

        smoothScrollingTo(scrollId);
    }

    changeStateModal = (open) => {
        this.setState({
            isOpenModal: open,
        });
    }

    render() {
        const {
            isOpen,
            activeRoute,
            headerClassName,
            isOpenModal,
        } = this.state;

        const {
            isCompletedAccount,
            url,
            dark = false,
            user,
        } = this.props;

        return (
            <>
                <header className={headerClassName}>
                    <div className="container">
                        <div className="header__logo">
                            <a href="/"><LogoImg /></a>
                        </div>
                        <div className="header__menu">
                            {model && model.menuLinks && (
                                <nav className="header__nav">
                                    <ul className="header__nav-list">
                                        {this.getMenuLinks().map(({
                                            name, route, scroll = '', color,
                                        }) => {
                                            if (!(name === 'Home' && url === route)) {
                                                return (
                                                    <>
                                                        <li key={`${route}_${name}`} className="header__nav-list-item">
                                                            <a href={route} onClick={(e) => this.handleOnClickLink(e, scroll, false)} className={color ? 'header__nav-link header__nav-link_color' : 'header__nav-link'}>
                                                                {name}
                                                            </a>
                                                        </li>
                                                    </>
                                                );
                                            }
                                        })}
                                        <li onClick={() => this.changeStateModal(true)} className="header__nav-list-item">
                                            <a className="header__nav-link">Ask a question</a>
                                        </li>
                                        {user && (
                                            <>
                                                <li className={`header__nav-list-item ${activeRoute === '/guide' ? 'active' : ''}`}>
                                                    <a href="/guide" className="header__nav-link">Guide</a>
                                                </li>
                                                <li className={`header__nav-list-item ${activeRoute === '/settings' ? 'active' : ''}`}>
                                                    <a href="/settings" className="header__nav-link">Settings</a>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </nav>
                            )}
                            <button
                                type="button"
                                className="header__burger-button burger-button"
                                onClick={this.onOpenMenu}
                                aria-label="Open menu"
                            >
                                <BurgerIcon />
                            </button>
                        </div>
                        <div className="header__right">
                            {!user ? (
                                <>
                                    <a className="header__right-login" href="/login">
                                        <Button
                                            text="Login"
                                            dark
                                        />
                                    </a>
                                    <a className="header__right-join">
                                        <ButtonColor
                                            onClick={(e) => this.handleOnClickLink(e, 'anchor-join', false)}
                                            text="Get started"
                                        />
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a className={`header__right-profile ${activeRoute === '/requests' ? 'active' : ''}`} href="/requests">
                                        {user.name}
                                        {' '}
                                        {user.lastName}
                                    </a>
                                    <a className="header__right-logout" href="/auth-api/logout">Logout</a>
                                </>
                            )}
                        </div>
                    </div>
                </header>

                <MobileMenu
                    isOpen={isOpen}
                    activeRoute={activeRoute}
                    links={this.getMenuLinks()}
                    onClose={this.onCloseMenu}
                    onClickLink={this.handleOnClickLink}
                    user={user}
                    changeStateModal={this.changeStateModal}
                />

                {!isCompletedAccount && <StopModal />}

                <AskModalQuestion changeStateModal={this.changeStateModal} isOpen={isOpenModal} />
            </>
        );
    }
}

SiteHeader.propTypes = {
    user: PropTypes.object,
    documentTitle: PropTypes.string,
    isCompletedAccount: bool,
    url: PropTypes.string,
    dark: PropTypes.bool,
};

SiteHeader.defaultProps = {
    user: null,
    documentTitle: 'Book Cargo Plane - Air Cargo Charter | World Trans Aero',
    isCompletedAccount: true,
    url: '/',
    dark: false,
};
