import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';
import './style.css';

export const MainTitle = ({
    text,
    modClass,
    size,
}) => (
    <h1
        className={smartClass('block-title', {
            [modClass]: !!modClass,
            'block-title_big': size === 'big',
        })}
    >
        <span>
            {text}
        </span>
    </h1>
);

MainTitle.propTypes = {
    text: PropTypes.string,
    modClass: PropTypes.string,
    size: PropTypes.string,
};

MainTitle.defaultProps = {
    text: '',
    modClass: '',
    size: '',
};
