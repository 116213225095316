import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import OutsideClickHandler from 'react-outside-click-handler';

import { smartClass } from '~/helpers/smart-class';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './style.css';

export const DatePicker = ({
    onChange,
    modClass,
    placeholder,
    viewValue,
    minDate,
    ranges,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={smartClass('control-form', modClass)}>
            <input
                disabled={disabled}
                readOnly
                required
                type="text"
                value={viewValue}
                placeholder={placeholder}
                onClick={() => setIsOpen(!isOpen)}
                className="control-form__input control-form__input--date"
            />
            {isOpen && (
                <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                    <div className="control-form__datepicker">
                        <DateRange
                            onChange={onChange}
                            minDate={minDate}
                            ranges={ranges}
                        />
                    </div>
                </OutsideClickHandler>
            )}
        </div>
    );
};

DatePicker.propTypes = {
    ranges: PropTypes.array,
    onChange: PropTypes.func,
    modClass: PropTypes.string,
    placeholder: PropTypes.string,
    viewValue: PropTypes.string,
    minDate: PropTypes.object,
};

DatePicker.defaultProps = {
    ranges: [{
        startDate: new Date(),
        endDate: null,
        key: 'selection',
    }],
    onChange: () => { },
    modClass: '',
    placeholder: 'Date',
    viewValue: '',
    minDate: null,
};
