import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { smartClass } from '~/helpers/smart-class';

import './style.css';

export const Input = ({
    type,
    value,
    placeholder,
    onChange,
    required,
    modClass,
    inputRef,
    accept,
    isNumber,
    disabled,
    measure,
    maxLength,
    capitalLetter = false,
    setShowPass,
    showPass,
    autoComplete = false,
    autocompleteValue = 'one-time-code',
    name = '',
}) => {
    const isFile = type === 'file';
    const inputModel = {
        type,
        onChange,
        placeholder,
        className: 'control-form__input',
        required,
        ref: inputRef,
        disabled,
        name,
    };

    if (!autoComplete && autocompleteValue) inputModel.autocomplete = autocompleteValue;

    if (isFile) {
        inputModel.accept = accept;
        inputModel.style = { display: 'none' };
    } else {
        inputModel.value = capitalLetter && value
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value;
    }

    return (
        <div className={smartClass('control-form', modClass)}>
            {isFile && (
                <input
                    disabled={disabled}
                    type="text"
                    placeholder={placeholder}
                    className="control-form__input"
                    required={required}
                    value={value}
                    onClick={() => inputRef.current.click()}
                    style={{ cursor: 'pointer' }}
                    readOnly
                />
            )}

            {isNumber && (
                <NumberFormat
                    disabled={disabled}
                    thousandSeparator=" "
                    className="control-form__input"
                    placeholder={placeholder}
                    value={value}
                    onValueChange={onChange}
                    required={required}
                    ref={inputRef}
                    allowNegative={false}
                    isAllowed={({ value }) => value.length < (maxLength || 15)}
                    suffix={measure}
                />
            )}

            {!isNumber && <input {...inputModel} />}
            {setShowPass && (
                <div
                    onClick={() => setShowPass(!showPass)}
                    className={
                        showPass
                            ? 'pass-eye-icon'
                            : 'pass-eye-icon pass-eye-icon_hide'
                    }
                />
            )}
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    isNumber: PropTypes.bool,
    modClass: PropTypes.string,
    inputRef: PropTypes.object,
    accept: PropTypes.string,
};

Input.defaultProps = {
    type: 'text',
    value: '',
    placeholder: '',
    onChange: () => { },
    required: false,
    isNumber: false,
    modClass: '',
    inputRef: {},
    accept: '',
};
