import { createStore, applyMiddleware } from 'redux';

import { getInitialData } from './get-initial-data';

import { appReducer } from '../reducers';

import { pageMiddleware } from '../middlewares';

export const getStore = (initialState) => applyMiddleware(pageMiddleware())(createStore)(
    appReducer,
    getInitialData(initialState),
);
