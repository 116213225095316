import {
    CHANGE_JOIN_VALUES,
    CLEAR_JOIN_VALUES,
    FETCH_JOIN,
    SET_JOIN_PROCESSING,
} from '../types';

export const changeJoinValues = (payload) => ({
    type: CHANGE_JOIN_VALUES,
    payload,
});

export const clearJoinValues = () => ({
    type: CLEAR_JOIN_VALUES,
});

export const fetchJoin = () => ({
    type: FETCH_JOIN,
});

export const setJoinProcessing = (joinProcessing) => ({
    type: SET_JOIN_PROCESSING,
    joinProcessing,
});
