import {
    CHANGE_JOIN_VALUES,
    CLEAR_JOIN_VALUES,
    SET_JOIN_PROCESSING,
} from '../types';

import {
    changeJoinValues,
    clearJoinValues,
    setJoinProcessing,
} from './page-reducers';

const handlers = {
    [CHANGE_JOIN_VALUES]: changeJoinValues,
    [CLEAR_JOIN_VALUES]: clearJoinValues,
    [SET_JOIN_PROCESSING]: setJoinProcessing,
};

export const appReducer = (initialState = {}, action) => {
    const handler = handlers[action.type];

    if (handler) {
        return handler(initialState, action);
    }

    return initialState;
};
