import React from 'react';

import airplaneImg from '../../assets/img/notebook.png';

import './style.scss';

export const SectionProblem = () => (
    <div className="section-problem">
        <div className="container">
            <div className="section-problem__left">
                <h2 className="section-problem__subtitle">
                    Problem
                </h2>
                <div className="block-title section-problem__title">
                    Lack of a clear and transparent market makes it impossible to book cargo planes quickly and easily
                </div>
                <div className="section-problem__text">
                    Difficulties arise at all stages of work, from search and chartering of planes to overpricing and sending empty return flights
                </div>
            </div>
            <div className="section-problem__right">
                <img src={airplaneImg} alt="" />
            </div>
        </div>
    </div>
);
