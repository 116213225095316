import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';

import AirplaneIcon from './assets/airplane.svg';
import LoaderIcon from '../../assets/images/loader.svg';

import './style.css';

export const MainButton = ({
    text,
    type,
    modClass,
    onClick,
    processing,
    icon,
    size,
    disabled
}) => (
    <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={smartClass('main-button',
            {
                [modClass]: !!modClass,
                'main-button--sm': size === 'sm',
            })}
    >
        {!processing && (
            <>
                {text}
                {icon && (
                    <span className="main-button__icon"><AirplaneIcon /></span>
                )}
            </>
        )}
        {processing && <span className="main-button__loader"><LoaderIcon /></span>}
    </button>
);

MainButton.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    modClass: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    processing: PropTypes.bool,
    icon: PropTypes.bool,
    dark: PropTypes.bool,
};

MainButton.defaultProps = {
    text: '',
    type: 'button',
    modClass: '',
    size: '',
    onClick: () => { },
    processing: false,
    icon: true,
    dark: false,
    uppercase: true
};
