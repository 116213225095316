import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';

export const ButtonColor = ({
    text,
    type,
    modClass,
    onClick,
    processing,
    size,
    dark,
    disabled,
    wide,
}) => (
    <button
        disabled={disabled}
        type={type}
        onClick={onClick}
        className={smartClass('button button_color',
            {
                [modClass]: !!modClass,
                'main-button--sm': size === 'sm',
                'main-button--dark': dark,
                'button_wide': wide,
            })}
    >
        {!processing && (
            <>
                {text}
            </>
        )}
    </button>
);

ButtonColor.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    modClass: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    processing: PropTypes.bool,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
};

ButtonColor.defaultProps = {
    text: '',
    type: 'button',
    modClass: '',
    size: '',
    onClick: () => { },
    processing: false,
    dark: false,
    disabled: false,
};
