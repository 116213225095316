import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

import { setNotification } from '~/components/notification';
import { smartClass } from '~/helpers/smart-class';

import TriangleBottomIcon from '../assets/triangle-bottom.svg';

import './style.css';

export class AirportSelect extends PureComponent {
    state = {
        options: [],
        value: null,
        loading: null,
        forceReloadValue: false,
        // eslint-disable-next-line react/destructuring-assignment
        placeholder: this.props.placeholder,
    }

    selectRef = null;

    componentDidUpdate(prevProps) {
        const { selectedValue } = this.props;
        const { forceReloadValue } = this.state;

        if (prevProps.selectedValue !== selectedValue || forceReloadValue) {
            this.getSelectedValue();
        }
    }

    getNoOptionsMessage = ({ inputValue }) => {
        const { options } = this.state;

        if (inputValue && options.length < 1) {
            return `No found by: ${inputValue}`;
        }

        return 'To find start writing';
    }

    getSelectedValue = async () => {
        const { selectedValue } = this.props;

        this.setState({ forceReloadValue: false });

        if (!selectedValue) {
            this.setState({ value: null });
            return;
        }

        this.setState({ loading: 'Loading ...' });

        try {
            const { data } = await axios.get(`/airports-api/get-airports-by-id?id=${selectedValue}`);

            this.setState({
                value: {
                    value: selectedValue,
                    label: data.iata_code ? `${data.icao_code} (${data.iata_code})` : `${data.icao_code}`,
                },
            });
        } catch (err) {
            setNotification({ type: 'error' });
        }

        this.setState({ loading: null });
    }

    handleOnChange = (valObj) => {
        const { loading } = this.state;
        this.selectRef.select.select.blur();

        if (loading) {
            return;
        }

        const { onChange } = this.props;
        onChange(valObj);
    }

    handleOnFocus = () => {
        this.setState({ value: null, placeholder: '' });
    }

    handleOnBlur = () => {
        const { placeholder } = this.props;
        this.setState({ placeholder, forceReloadValue: true });
    }

    promiseOptions = (slug) => new Promise((resolve) => {
        clearTimeout(this.timeout);

        if (!slug) {
            resolve([]);
            return;
        }

        this.timeout = setTimeout(async () => {
            try {
                const { data } = await axios.get(`/airports-api/get-airports-by-slug?slug=${slug}`);

                this.setState({ options: data });

                resolve(data.map(({
                    // eslint-disable-next-line camelcase
                    objectID, name, city, country, icao_code, iata_code,
                    // eslint-disable-next-line camelcase
                }) => (iata_code ? { value: objectID, label: `${icao_code} (${iata_code}), ${name} – ${country} ${city}` }
                    : { value: objectID, label: `${icao_code}, ${name} – ${country} ${city}` })));
            } catch (err) {
                setNotification({ type: 'error' });
                resolve([]);
            }
        }, 500);
    });

    render() {
        const { value, loading, placeholder } = this.state;
        const { modClass, disabled } = this.props;

        const customStyles = {
            option: (provided) => ({
                ...provided,
                color: '#11192B',
                '&:hover': {
                    background: '#0E3CC0',
                    color: '#fff',
                },
            }),
            control: (provided, state) => ({
                ...provided,
                border: state.isFocused ? '2px solid #11192B66' : '1px solid #11192B66',
                boxShadow: 'none',
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'black',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: '#11192B66',
            }),
        };

        return (
            <div className={smartClass('control-form-select-container control-form-select-airport', modClass)}>
                <AsyncSelect
                    ref={(ref) => {
                        this.selectRef = ref;
                    }}
                    placeholder={loading || placeholder}
                    isDisabled={disabled}
                    defaultProps
                    optionsCache
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    noOptionsMessage={this.getNoOptionsMessage}
                    loadOptions={this.promiseOptions}
                    classNamePrefix="control-form-select"
                    styles={customStyles}
                    value={loading ? null : value}
                    components={{
                        DropdownIndicator: () => <TriangleBottomIcon />,
                        IndicatorSeparator: () => null,
                    }}
                />
            </div>
        );
    }
}

AirportSelect.propTypes = {
    placeholder: PropTypes.string,
    modClass: PropTypes.string,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
};

AirportSelect.defaultProps = {
    placeholder: 'Airport',
    modClass: '',
    selectedValue: '',
    onChange: () => { },
};
