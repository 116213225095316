export const TYPE_IDS = {
    owner: 1,
    customer: 2,
};

export const USER_TYPES = {
    [TYPE_IDS.owner]: 'Owner',
    [TYPE_IDS.customer]: 'Customer',
};

export const isOwner = (id) => id === TYPE_IDS.owner;
