import axios from 'axios';
import { setNotification } from '~/components/notification';

import { FETCH_JOIN } from '../types';

import { clearJoinValues, setJoinProcessing } from '../actions';

export const pageMiddleware = () => (store) => (next) => async (action) => {
    const storeData = store.getState();
    const { type: actionType } = action;
    const { dispatch } = store;

    switch (actionType) {
    case FETCH_JOIN: {
        const { joinSelectedValues: registrationData } = storeData;

        dispatch(setJoinProcessing(true));
        let success = false;

        if (
            registrationData
                && registrationData.password !== registrationData.repeatPassword
        ) {
            setNotification({
                type: 'error',
                message: 'Passwords do not match',
            });
            dispatch(setJoinProcessing(false));
            return;
        }

        try {
            const { data } = await axios.post(
                '/registration-api/registration',
                {
                    registrationData,
                },
            );

            if (data.errors) {
                setNotification({
                    type: 'error',
                    message: data.errors,
                });
            }

            if (data.success) {
                setNotification({
                    type: 'success',
                    message: data.success,
                });
                success = true;
            }
        } catch (err) {
            setNotification({
                type: 'error',
                message: err,
            });
        }

        if (success) {
            dispatch(clearJoinValues());
        }

        dispatch(setJoinProcessing(false));

        break;
    }

    default:
        break;
    }

    next(action);
};
