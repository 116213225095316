import React from 'react';

import './style.scss';

export const SectionWorkflow = () => (
    <div id="anchor-workflow" className="section-workflow">
        <div className="container">
            <div className="section-workflow__title block-title_big">
                Workflow consistency
            </div>
            <div className="section-workflow__list">
                <div className="section-workflow__item">
                    <div className="section-workflow__item-text">
                        Create a request. Fill in main information fields
                    </div>
                </div>
                <div className="section-workflow__item">
                    <div className="section-workflow__item-text">
                        Get real-time personalized offers from interested airlines
                    </div>
                </div>
                <div className="section-workflow__item">
                    <div className="section-workflow__item-text">
                        Book directly suitable variant
                    </div>
                </div>
            </div>
        </div>
    </div>
);
