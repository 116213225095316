import aircraftsData from "./data/aircrafts.json";

export const getAircraftBySlug = (slug) => {
    if (!slug) {
        return [];
    }

    const cleanSlug = slug.toLowerCase();

    const filterByNames = aircraftsData.filter(
        ({ name }) => `${name}`.toLowerCase().indexOf(cleanSlug) === 0,
    );

    return filterByNames;
};
