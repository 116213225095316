export const smartClass = (...classes) => {
    const active = [];

    const check = (cl) => {
        if (typeof cl === "string") {
            active.push(cl);
            return;
        }
        if (typeof cl === "object") {
            Object.keys(cl).forEach((key) => cl[key] && active.push(key));
        }
    };

    classes.forEach(check);
    return active.join(" ");
};
