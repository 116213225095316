import React, { useEffect, useState } from 'react';
import { setCookie, getCookie } from '~/helpers/cookie';

import './style.scss';
import { ButtonColor } from '../../buttons/buttonColor';
import { Button } from '../../buttons/button';

export const CookieModal = () => {
    const [isOpen, changeOpen] = useState(false);

    useEffect(() => {
        const open = !getCookie('cookieModal');
        changeOpen(open);
    }, []);

    const closeModal = (accept = false) => {
        if (accept) {
            changeOpen(false);
            setCookie('cookieModal', 'true', 2);
        } else {
            changeOpen(false);
            setCookie('cookieModal', 'true', 360);
        }
    };

    return (
        <>
            {isOpen && (
                <div className="cookie-modal">
                    <div className="cookie-modal__wrap">
                        <div className="cookie-modal__text">
                            We use cookies that make the web page easier to use.&nbsp;
                            <a className="cookie-modal__link" href="/cookie-policy">Cookie policy</a>
                        </div>

                        <div className="modal__buttons">
                            <ButtonColor
                                onClick={() => closeModal(true)}
                                text="Accept"
                            />
                            <Button
                                onClick={() => closeModal()}
                                text="Reject"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
