import { getRequestControls } from '../helpers';

export const getInitialData = ({
    requestControls, qaList, user, url,
}) => ({
    user,
    url,
    currentBlockId: undefined,
    requestControls: getRequestControls(requestControls),
    requestSelectedValues: {},
    requestProcessing: false,
    qaList,
    questionSelectedValues: {},
    questionProcessing: false,
    joinSelectedValues: {},
    joinProcessing: false,
});
