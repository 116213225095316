import React from 'react';
import PropTypes from 'prop-types';

import { smartClass } from '~/helpers/smart-class';
import CrossIcon from '../../assets/cross.svg';

import './style.css';
import { Button } from '../../../buttons';

export const MobileMenu = ({
    isOpen,
    links,
    onClose,
    activeRoute,
    onClickLink,
    user,
    changeStateModal,
}) => isOpen && links && (
    <div onClick={(e) => onClose(e, true)} className="menu mobile-menu overlay">
        <nav className="menu__nav">
            <ul className="menu__nav-list">
                {user && (
                    <>
                        <li className="menu__nav-list-item">
                            <a href="/requests" className="menu__nav-link">Requests</a>
                        </li>
                        <li className="menu__nav-list-item">
                            <a href="/guide" className="menu__nav-link">Guide</a>
                        </li>
                        <li className="menu__nav-list-item">
                            <a href="/settings" className="menu__nav-link">Setting</a>
                        </li>
                    </>
                )}
                {links.map(({
                    name, route, mobile, scroll = '',
                }) => mobile && (
                    <li
                        key={`${route}_${name}`}
                        className={smartClass('menu__nav-list-item', {
                            'active': activeRoute === route,
                        })}
                    >
                        <a href={route} className="menu__nav-link" onClick={(e) => onClickLink(e, scroll, mobile)}>
                            {name}
                        </a>
                    </li>
                ))}
                <li className="menu__nav-list-item">
                    <a className="menu__nav-link" onClick={() => changeStateModal(true)}>
                        Ask a question
                    </a>
                </li>
            </ul>

            <div className="mobile-menu__btns">
                {!user ? (
                    <>
                        <a href="/login">
                            <Button
                                text="Login"
                            />
                        </a>
                        <a onClick={(e) => onClickLink(e, 'anchor-join', false)}>
                            <Button
                                text="Get started"
                            />
                        </a>
                    </>
                ) : (
                    <>
                        <a href="/auth-api/logout">
                            <Button
                                text="Logout"
                            />
                        </a>
                    </>
                )}
            </div>

            <button type="button" onClick={(e) => onClose(e, true)} className="menu__close-button">
                <CrossIcon />
            </button>
        </nav>
    </div>
);

MobileMenu.propTypes = {
    isOpen: PropTypes.bool,
    links: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    activeRoute: PropTypes.string,
    onClickLink: PropTypes.func,
    changeStateModal: PropTypes.func,
    user: PropTypes.object,
};

MobileMenu.defaultProps = {
    isOpen: false,
    onClose: () => { },
    activeRoute: '',
    onClickLink: () => { },
    changeStateModal: () => {},
    user: null,
};
