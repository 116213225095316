const namespace = 'main-page:';

const CHANGE_JOIN_VALUES = `${namespace}:change-join-values`;
const CLEAR_JOIN_VALUES = `${namespace}:clear-join-values`;
const FETCH_JOIN = `${namespace}:fetch-join`;
const SET_JOIN_PROCESSING = `${namespace}:set-join-processing`;

export {
    CHANGE_JOIN_VALUES,
    CLEAR_JOIN_VALUES,
    FETCH_JOIN,
    SET_JOIN_PROCESSING,
};
