import React from 'react';

import './style.css';

export const Tooltip = (props) => {
    return (
        <div className="tooltip">
            <div className="tooltip__conent">
                {props.text}
            </div>
        </div>
    )
}